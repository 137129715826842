<template>
  <div class="cursos">
    <Menu2></Menu2>
    <Menu></Menu>
    <b-navbar  class="mobile second-menu" toggleable type="dark" variant="dark">
      <b-navbar-brand href="#">Cursos por área</b-navbar-brand>
      <b-navbar-toggle target="navbar-toggle-collapse">
        <template #default="{ expanded }">
          <div v-if="expanded"><img class="icon-up" src="../Imagenes/flecha_arriba.png" alt=""></div>
          <div v-else><img class="icon-down" src="../Imagenes/flecha_abajo.png" alt=""></div>
        </template>
      </b-navbar-toggle>
      <b-collapse id="navbar-toggle-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item href="#" 
            v-for="(cat, index) in categories" 
            :key="index"
            @click.prevent="changeCategory(cat.category, cat.tag)">
            <span style="color:#5258c0">Cursos | </span>{{cat.category}}
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div class="banner mobile">
      <img src="../Imagenes/cursos/curso-mobile.png" alt="">
    </div>
    <div class="total-container">
      <div class="selection-box portrait">
        <div class="search">
          <input  type="text" placeholder="Buscar" v-model="search" @keydown.enter="searchCourses">
          <div class="search_2">
            <img src="../Imagenes/lupa.png" alt="buscar">
          </div>  
        </div>
        <div class="selection-box-one">
          <h5>Cursos por áreas</h5>
          <a 
            href="#" 
            v-for="(cat, index) in categories" 
            :key="index"
            @click.prevent="changeCategory(cat.category, cat.tag)"
            >
            {{cat.category}}
          </a>
        </div>
      </div>
      <div class="content-box">
        <div class="banner portrait">
         <img src="../Imagenes/cursos/curso-escritorio.png" alt="">
        </div>
        <div class="title-courses">
          <h4 v-if="currentTag != 'search'">Cursos de {{currentCat}}</h4>
          <h4 v-else>{{currentCat}}</h4>
        </div>
        <div class="courses-box">
          <div v-if="currentCourses.length == 0">
            <div class="box-no-found">
              <h6>Por el momento no tenemos cursos para ofrecerte <br> con respecto a tu busqueda '<span style="">{{this.caTemail}}</span>'</h6>
              <div class="box2-no-found">
                <p>Puedes revisar los cursos disponibles en otras categorias o bien déjanos tu mail para contarte cuando publiquemos un nuevo curso en esta área.</p><br>
                <div>
                  <input id="no-found" type="text" v-model="email" name="email" placeholder="Ingresa tu mail">
                  <div class="box-no-found-send">
                    <p>Quiero recibir información en mi mail</p>
                    <input class="btn-contact"  @click="checkForm" type="submit" value="Enviar">
                  </div>
                  <div class="error">
                    <p v-if="errors.length">
                      <ul>
                          <li v-for="(error,index) in errors" :key="index">{{ error }}</li>
                      </ul>
                    </p>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div class="courses-card" v-for="(course, index) in currentCourses" :key="index">
            <a href="#" @click.prevent="selectCourse(course)">
              <div class="courses-card-image">
                <img :src="course.image" alt="">
              </div>
              <div class="course-card-text">
                <p>{{course.category}}</p>
                <h6>{{course.title}}</h6>
              </div>
            </a>
          </div>
          <div v-if="coursesShow" class="modal-bc-contact">
            <div class="modal-container">
              <div class="close-box">
                <a @click="ClosePopUp"><img src="../Imagenes/cerrar.png" alt="close Course"></a>
              </div>
              <div class="modal-ghost">
                <div class="courses-title mobile">
                  <p class="little-category">{{selectedCourse.category}}</p>
                </div>
                <div class="modal-courses-content">
                  <div class="img-courses">
                    <img :src="selectedCourse.image" alt="Curso">
                  </div>
                  <div class="box-courses">
                    <div class="courses-title portrait">
                      <p class="little-category">{{selectedCourse.category}}</p>
                    </div>
                    <h6>{{selectedCourse.title}}</h6>
                    <p class="little-p">{{selectedCourse.description}}</p>
                    <!--<div class="price">
                      <p>$ {{selectedCourse.price}}</p>
                    </div>-->
                    <div class="btn-take-course" @click=courseInterest>
                      <a>Contactar</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer @search-course="footerSearch($event)"></Footer>
  </div>
</template>

<script>
import courses from '../../src/views/cursos.json'
import Menu from '@/components/Menu.vue';
import Menu2 from '@/components/Menu2.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';

export default {
  name: 'Cursos',
  components: {
    Menu,
    Menu2,
    Footer,
  },
  data() {
    return {
      search: '',
      courses: courses,
      coursesShow: false,
      categories: [],
      currentCat: '',
      currentTag: '',
      currentCourses: [],
      selectedCourse: {},
      caTemail:'',
      name: 'Cliente sección busqueda de cursos',
      email: '',
      subject: 'Busqueda curso sin exito',
      message: 'La busqueda del cliente fue:',
      errors: [],
      courseSelect:'',
    }
  },

  methods: {
    sendInformation(){
      let data = {
          "to": "contacto@alteraacademy.com", 
          "from": this.email,
          "sender":this.name,
          "subject": this.subject,
          "msg": 'Email contacto:'+ this.email +'<br>' + this.message + "'"+this.caTemail+"'"
        }
        axios
          .post('https://sender.alteracloud.com/send',data)
          .then((response)=>{
            console.log(response.data);
            this.email='';
            this.caTemail='';
            this.errors.push('Su email fue enviado con exito');
            setTimeout(() => {
              this.errors=[];}, 900)
            // this.$router.push({ name: "confirmacion"})
          })
          .catch((error)=>{
            console.log(error)
          })
    },
    checkForm() {
      this.errors = [];
      if (this.email.length<6) {
        this.errors.push('Sus datos deben tener como  mínimo 5 caracteres');
      }
      if (!this.errors.length) {
        this.sendInformation();
      } else {
        return false;
      }
    },   
    getCategories() {
      let categories = []
      let tags = []
      courses.forEach((course)=> {
        let norm = course.category.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/ /g, '-')
        categories.push(course.category)
        tags.push(norm)
      })

      categories = [...new Set(categories)]
      tags = [...new Set(tags)]
      
      categories.forEach((cat, index)=> {
        this.categories.push({
          category: cat,
          tag: tags[index]
        })
      })

      this.currentCat = this.categories[0].category
      this.currentTag = this.categories[0].tag
      this.getCourses()
    },
    getCourses() {
      let courses = this.courses.filter((course)=> {
        let norm = course.category.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/ /g, '-')
        return norm == this.currentTag
      })

      this.currentCourses = courses
    },
    changeCategory(cat, tag) {
      this.currentCat = cat
      this.currentTag = tag
      this.getCourses()
      window.history.pushState(tag, cat,`/cursos/?cat=${tag}`)
    },
    selectCourse(course) {
      this.selectedCourse = course
      this.coursesShow= true
    },
    footerSearch(e) {
      this.search = e
      this.searchCourses()
    },
    ClosePopUp(){
      this.coursesShow = !this.coursesShow
    },
    searchCourses() {
      let search = this.search.trim().toLowerCase()
      let filteredCourses = courses.filter((course)=> {  
        let title = course.title.toLowerCase()
        return title.includes(search)
      })

      this.currentCat = "Búsqueda de cursos"
      this.currentTag = "search"
      this.currentCourses = filteredCourses

      window.history.pushState(this.currentTag, this.currentCat, `/cursos/?search=${search}`)
        this.caTemail=this.search
        this.search= ''
        
    },
    getParams(param) {
      let queryString = window.location.search
      let params = new URLSearchParams(queryString)
      return params.get(param) 
    },
    courseInterest() {    
      let courseSelect = this.selectedCourse.title.trim().toLowerCase();
      if(this.$route.path !== '/form_course/') {
        window.location = `/form_course/?courseSelect=${courseSelect}`
      } else {
        this.$emit('interest-course', courseSelect)
        this.courseSelect = ''
      }
    },
  },

  mounted() {
    this.getCategories()
    let search = this.getParams('search')
    if(search) {
      this.search = search
      this.searchCourses()

      return
    }

    let category = this.getParams('cat')
    if(category) {
      this.currentTag = category
      this.getCourses()
      this.currentCat = this.currentCourses[0].category
    } 

  }
};
</script>

<style scoped>
.cursos{
  background-color: #f2f2f2;
  font-family: "Poppins";
}

.total-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  color: #41455d;
}
.second-menu{
  padding-top: 6rem;
  position: fixed;
  width: 100%;
}
.bg-dark {
    background-color: #fff!important;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.navbar-dark .navbar-brand {
    color: #5258c0;
    padding-left: 2em;
    padding-top: 0.7em;
    font-size: 18px;
    font-weight: 600;
}
.navbar-dark .navbar-nav .nav-link {
    color: #000;
    text-align: left;
    padding-left: 2.5em;
}
.icon-up,.icon-down{
  width: 1rem;
  margin-right: 1rem;
}

.selection-box{
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
}
.selection-box-one{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
}
.selection-box-one a{
  text-decoration: none;
  color: #41455d;
  margin-bottom: 0.5rem;
  font-size: 13px;
  font-weight: lighter;
}
.selection-box-one h5{
  margin-bottom: 0.8rem;
  font-weight: 600;
}
.search{
  color: #fff;
  background-color: #41455d;
  border-radius: 20px;
  border:0px solid #41455d;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 30px;
}
.search input{
  color: #fff;
  background-color: #41455d;
  border-radius: 20px;
  border:1px solid #41455d;
  width: 85%;
  padding-left: 1rem;
  height: 30px;
}
.search img{
  width: 1.3rem;
}
.search::placeholder{
  color: #fff;
  padding: 0rem 0.5rem;
  font-size: 14px;
  font-style: italic;
}
.search_2{
  background-color: #525bb7;
  height: 41px;
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #525bb7;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 30px;
}
.content-box{
  width: 70%;
}
.banner img{
  width: 100%;
  margin-top: 8rem;
}

.title-courses{
  margin-top: 2rem;
  text-align: left;
  color: #624fc7;
}
.courses-box{
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  padding: 1rem 0rem;
  text-align: left;
}
.courses-card{
  background-color: #fff;
  max-width: 230px;
  padding: 1rem;
  border-radius: 12px;
  margin: 1rem;
}
.courses-card a {
  text-decoration: none;
  color: #41455d;
}
.courses-card a:hover {
  color: #41455d;
}
.courses-card img{
  max-width: 200px;
}
.courses-card-image img{
  border-radius: 12px;
}
.course-card-text p{
  font-size: 10px;
  margin-top: 0.5rem;
}
.course-card-text h6{
  font-weight: 600;
}
.img-courses img{
  width: 24rem;
  border-radius: 12px
}
.modal-bc-contact{
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-container{
  background-color: #fff;
  border-radius: 12px;
}
.box-courses{
  max-width: 250px;
  margin: 0rem 2rem;
}
.modal-ghost{
  padding: 0rem 1rem 1rem 1rem;
}
.modal-courses-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.close-box{
  width: 102%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 1rem;
}
.close-box img{
  width: 1.5rem;
  cursor:pointer;
  margin-top: -100%;

}

.little-category{
  text-decoration: underline;
  font-size: 11px;
}
.box-courses h6{
  font-size: 14px;
  font-weight: 800;
}
.little-p{
  font-size: 12px;
  text-align: left;
}

.courses-title{
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}
.price{
  background-color: #10d5d5;
  color: #5a5bc8;
  font-weight: 6
  00;
  font-size: 12px;
  width: 4rem;
  font-style: oblique;
}

.btn-take-course{
  border: 1.5px solid #4ff5dd;
  background-color:#4ff5dd ;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 1rem;
  cursor: pointer;
}

.btn-take-course a{
    color: #525bb7!important;
    font-weight: 600;
    font-size: 12px;
    text-decoration: none;
}
.box-no-found{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  color: #9e9e9e;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.box-no-found h6{
  font-weight: bold;
  color: #908f8f;
  margin-bottom: 1rem;
}
.box2-no-found{
  max-width: 450px;
}
#no-found{
  background-color:#cecece;
  border: 1px solid #cecece;
  border-radius: 18px;
  padding: 0.5rem 1rem;
  color:#9e9e9e;
  margin-bottom: 1rem;
}
.box-no-found-send{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.box-no-found-send p{
  font-size: 10px;
  margin: 0px;
}
.btn-contact{
    border: 1.5px solid #4ff5dd;
    background-color: #4ff5dd;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
    width: 80px;
    cursor: pointer;
    color: #5258c0;
    font-weight: bold;
}
.error{
   margin-top: 2rem;
   width: 100%;
   background-color: #f2f2f2;
   font-size: 10px;
}
.error ul li{
    list-style: none;
}
input:focus,
input:active {
  outline: none;
}


@media (max-width: 991px){
  .portrait{
      display: none!important;
    }
  .content-box{
    width: 90%;
  }
  .cursos-box{
    align-content: center;
    justify-content: center;
  }
  .img-courses img{
    width: 15rem;
    margin-bottom: 1rem;
  }
  .box-courses{
    margin: 0rem;
  }
  .title-courses{
    text-align: center;
  }
}
@media (min-width: 992px){
  .total-container{
    padding: 8rem 4rem 4rem 4rem;
  }
  .mobile{
    display: none!important;
  }
  .banner img{
    margin-top: 0rem;
  }
  .cursos-box{
    padding: 2rem 0rem;
  }
  .modal-courses-content{
    flex-direction: row;
  }
  .btn-take-course{
    width: 8rem;
  }
}

</style>